import { frontendURL } from '../../../helper/URLHelper';
const SettingsWrapper = () => import('../settings/Wrapper.vue');
const KanbanView = () => import('./components/KanbanView.vue');

export const routes = [
  {
    name: 'kanban_dashboard',
    path: frontendURL('accounts/:accountId/kanban'),
    meta: {
      permissions: ['administrator', 'agent', 'kanban_manage'],
    },
    component: SettingsWrapper,
    props: {
      headerTitle: 'Kanban',
      icon: 'alert',
      showNewButton: false,
      showSidemenuIcon: false,
    },
    children: [
      {
        path: '',
        name: 'kanban_index',
        meta: {
          permissions: ['administrator', 'agent', 'kanban_manage'],
        },
        component: KanbanView,
      },
    ],
  },
];
